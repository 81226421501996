import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import styled from "@emotion/styled";

import Accordion from "../../../shared/components/Accordion/Accordion";
import Typography from "../../../shared/components/Typography/Typography";
import { kenkoDeepTeal } from "../../../shared/styles/colors";
import Button from "../../../shared/components/Button/Button";
import { useRouter } from "../../../shared/hooks";
import { faqQuestions } from "../../FAQ/FaqList";
import { shouldRedirectToNewFlow } from "../../../shared/utils/helper.utils";
import { setReferral } from "../../../slices/tracker.slice";

const FAQWrapper = styled(Grid)`
  padding: 80px 5vw;

  @media (max-width: 992px) {
    padding: 60px;
  }

  @media (max-width: 600px) {
    padding: 32px 5vw;
  }

  .heading {
    color: ${kenkoDeepTeal};
    text-align: center;
    opacity: 0.87;
    font-size: 40px !important;
    margin-bottom: 80px;

    @media (max-width: 600px) {
      font-size: 22px !important;
      margin-bottom: 40px;
    }
  }

  .accordian-wrapper {
    margin: auto;
    width: 60%;

    @media (max-width: 1920px) {
      width: 65%;
    }
    @media (max-width: 992px) {
      width: 90%;
    }
    @media (max-width: 600px) {
      width: 95%;
    }
  }

  .wrapper-class {
    display: flex;
    width: 100%;
  }

  .faq-button {
    width: 248px;
    height: 57px;
    display: flex;
    justify-content: center;
    margin: 48px auto 0 auto;
    border-radius: 10px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    background-color: #fbfbf6 !important;
    border: 1px solid #124647 !important;
    box-shadow: unset !important;

    @media (max-width: 600px) {
      width: 184px;
      height: 44px;
      font-size: 16px !important;
      margin: 24px auto 0;
    }
  }
`;

export const FAQSection = ({ referral = false }: { referral?: boolean }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const utm = useSelector((state: any) => state.utmReducer);
  const { isReferral } = useSelector((state: any) => state.tracker);
  const newFlow = shouldRedirectToNewFlow(utm);

  return (
    <FAQWrapper container>
      <Grid item xs={12}>
        <Typography variant="h3" className="heading">
          Frequently Asked Questions (FAQs)
        </Typography>
      </Grid>

      <div className="accordian-wrapper">
        <Accordion items={faqQuestions(newFlow, referral, isReferral)} />
      </div>

      <Button
        label="View More"
        variant="outlined"
        className="faq-button"
        onClick={() => {
          if (referral) {
            dispatch(setReferral(true));
          }
          router.push("/faq");
        }}
      />
    </FAQWrapper>
  );
};

export default FAQSection;
